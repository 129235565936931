/* eslint react-hooks/exhaustive-deps: "warn" */

import { type Key, useMemo, useCallback, ReactNode } from 'react';

import cn from 'classnames';

import { useMediaQuery } from 'hooks/useMediaQuery';

import styles from './Table.module.scss';

export type Column<T> = {
  title: string | ReactNode;
  dataIndex: string;
  width?: number | string;
  fixed?: string;
  visible?: boolean | undefined;
  render: (record: T[keyof T], index: number) => JSX.Element | null;
};

export type Columns<T> = Column<T>[];

interface Props<T> {
  rowKey?: keyof T;
  title?: ReactNode;
  data: T[];
  columns: Columns<T>;
  actionHeader?: ReactNode;
  blurThreshold?: number;
}

export const Table = <T extends unknown>({
  rowKey = 'id' as keyof T,
  title,
  data = [],
  columns,
  actionHeader,
  blurThreshold,
}: Props<T>) => {
  const isSmallDesktop = useMediaQuery(768);

  const getValueByDataIndex = useCallback((item: T, dataIndex: keyof T): T[keyof T] => {
    if (typeof dataIndex === 'string') {
      return dataIndex.split('.').reduce<any>((acc, key) => acc?.[key], item);
    }

    return item[dataIndex];
  }, []);

  const [fixedColumnsLeft, fixedColumnsWidthLeft, fixedColumnsRight, fixedColumnsWidthRight, normalColumns] =
    useMemo(() => {
      const $fixedColumnsLeft = columns.filter((column) => column.fixed === 'left' && column.visible !== false);
      const $fixedColumnsRight = columns.filter((column) => column.fixed === 'right' && column.visible !== false);

      return [
        $fixedColumnsLeft,
        $fixedColumnsLeft.reduce((totalWidth, column) => totalWidth + ((column && Number(column?.width)) || 0), 0),

        $fixedColumnsRight,
        $fixedColumnsRight.reduce((totalWidth, column) => totalWidth + ((column && Number(column?.width)) || 0), 0),

        columns.filter((column) => column.fixed !== 'left' && column.fixed !== 'right' && column.visible !== false),
      ];
    }, [columns]);

  const renderTitle = useCallback(() => {
    return title || `${data.length || 0} result${data.length !== 1 ? 's' : ''}`;
  }, [title, data]);

  if (isSmallDesktop) {
    return (
      <div className={styles['table']}>
        <div className={styles['table-header-actions']}>
          <div className={styles['table-title']}>{renderTitle()}</div>

          {actionHeader}
        </div>

        <div className={styles['table-wrapper']}>
          <table>
            <thead>
              <tr>
                <th style={{ width: fixedColumnsWidthLeft }} className={styles['table-head-fixed']}>
                  <div style={{ display: 'flex' }}>
                    {fixedColumnsLeft.map((column, key) => (
                      <div key={key} style={{ width: column?.width }}>
                        {column?.title}
                      </div>
                    ))}
                  </div>
                </th>

                {normalColumns.map((column, key) => (
                  <th key={key} style={{ width: column.width }}>
                    {column.title}
                  </th>
                ))}

                <th
                  style={{ width: fixedColumnsWidthRight }}
                  className={cn(styles['table-head-fixed'], styles['table-head-fixed-right'])}
                >
                  <div style={{ display: 'flex' }}>
                    {fixedColumnsRight.map((column, key) => (
                      <div key={key}>{column?.title}</div>
                    ))}
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => {
                const keyTypeOf = typeof item[rowKey];
                const key = (keyTypeOf === 'string' || keyTypeOf === 'number' ? item[rowKey] : index) as Key;

                return (
                  <tr key={key} className={styles['table-row']}>
                    <td
                      style={{ width: fixedColumnsWidthLeft }}
                      className={cn(
                        styles['table-body-fixed'],
                        blurThreshold && index >= blurThreshold && styles['table-cell-blur'],
                      )}
                    >
                      <div style={{ display: 'flex' }}>
                        {fixedColumnsLeft.map((column, columnIndex) => (
                          <div key={columnIndex} style={{ width: column?.width }}>
                            {column?.render(getValueByDataIndex(item, column.dataIndex as keyof T), index + 1)}
                          </div>
                        ))}
                      </div>
                    </td>

                    {normalColumns.map((column, columnIndex) => (
                      <td
                        key={columnIndex}
                        className={cn(blurThreshold && index >= blurThreshold && styles['table-cell-blur'])}
                        style={{ width: column.width }}
                      >
                        {column.render(getValueByDataIndex(item, column.dataIndex as keyof T), index + 1)}
                      </td>
                    ))}

                    {fixedColumnsRight.length > 0 && (
                      <td
                        style={{ width: fixedColumnsWidthRight }}
                        className={cn(
                          styles['table-body-fixed'],
                          styles['table-body-fixed-right'],
                          blurThreshold && index >= blurThreshold && styles['table-cell-blur'],
                        )}
                      >
                        <div style={{ display: 'flex' }}>
                          {fixedColumnsRight.map((column, columnIndex) => (
                            <div key={columnIndex} style={{ width: column?.width }}>
                              {column?.render(getValueByDataIndex(item, column.dataIndex as keyof T), index + 1)}
                            </div>
                          ))}
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['table-mobile']}>
      <div className={styles['table-mobile-title-wrapper']}>
        <div className={styles['table-mobile-title-value']}>{renderTitle()}</div>

        {actionHeader && <div className={styles['table-mobile-title-actions']}>{actionHeader}</div>}
      </div>

      {data.map((item, index) => {
        const keyTypeOf = typeof item[rowKey];
        const key = (keyTypeOf === 'string' || keyTypeOf === 'number' ? item[rowKey] : index) as Key;

        return (
          <div key={key} className={styles['table-mobile-wrapper']}>
            <div className={styles['table-mobile-card']}>
              {columns
                .filter((i) => i.dataIndex !== 'index' && i.visible !== false)
                .map((column) => {
                  const columnKey = column.dataIndex as Key;

                  return (
                    <div key={columnKey} className={styles['table-mobile-card-row']}>
                      <div className={styles['table-mobile-card-item-title']}>{column.title}</div>
                      <div className={styles['table-mobile-card-item-children']}>
                        {column?.render(getValueByDataIndex(item, column.dataIndex as keyof T), index + 1)}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
