/* eslint react-hooks/exhaustive-deps: "warn" */

import { useEffect, useMemo, useState } from 'react';

import { content } from 'libs/http/api/content/content';
import { Product } from 'libs/http/api/content/content.types';
import { external } from 'libs/http/api/external/external';
import { useRouter } from 'libs/router/useRouter';

interface Response {
  data: Product[] | null;
}

export const useProducts = (redirect: () => Promise<boolean>) => {
  const {
    query: {
      lang,
      public_grouped_by_type: publicGroupedByType,
      public_country: publicCountry,
      public_title: publicTitle,
      public_has_frequency_switcher: publicHasFrequencySwitcher = true,
      public_video: publicVideo,
      public_cta_type: publicCtaType,
      public_cta_text: publicCtaText,
      public_cta_link: publicCtaLink,
      public_presentation_id: publicPresentationId,
    },
  } = useRouter();

  const [data, setData] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async (
      country: string,
      lang: string | string[] | undefined,
      redirect: () => Promise<boolean>,
    ): Promise<Response> => {
      const options = { lang: `${lang}`, country };

      let mapToResponse = [];
      try {
        ({ data: mapToResponse } = await content.productMapTo(options));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        redirect();
      }

      if (mapToResponse.length === 0) {
        /** redirect if there is no country to map */
        redirect();
      }

      try {
        /** Get mapped to country or keep current if it was mapped properly */
        const finalCountry = mapToResponse[0]?.map_properly ? country : mapToResponse[0]?.map_to;

        const { data: dataResponse } = await content.products({
          ...options,
          country: finalCountry,
          ...(!publicGroupedByType ? { 'filter[type]': 'null' } : { 'filter[type__ne]': 'null' }),
        });

        if (dataResponse.length === 0) {
          /** redirect if there are no products */
          redirect();
        }

        return { data: dataResponse };
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        redirect();
      }

      return { data: null };
    };

    (async () => {
      if (typeof publicCountry === 'string') {
        const { data } = await fetchProducts(publicCountry, lang, redirect);

        if (data) {
          setData(data);
          setLoading(false);
        }

        return;
      }

      let response;
      try {
        ({ data: response } = await external.getIp());
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        return redirect();
      }

      if (response?.country_code2 !== undefined) {
        const { data } = await fetchProducts(response.country_code2.toLowerCase(), lang, redirect);

        if (data) {
          setData(data);
          setLoading(false);
        }
      }
    })();
  }, [lang, publicCountry, publicGroupedByType, redirect]);

  return {
    data,
    setData,
    loading,
    setLoading,

    iframeData: useMemo(
      () => ({
        title: publicTitle,
        country: publicCountry,
        groupedByType: Boolean(publicGroupedByType),
        hasFrequencySwitcher: publicHasFrequencySwitcher !== 'false',
        video: typeof publicVideo === 'string' ? publicVideo : undefined,
        ctaType: typeof publicCtaType === 'string' ? publicCtaType : undefined,
        ctaText: typeof publicCtaText === 'string' ? publicCtaText : undefined,
        ctaLink: typeof publicCtaLink === 'string' ? publicCtaLink : undefined,
        presentationId: typeof publicPresentationId === 'string' ? publicPresentationId : undefined,
      }),
      [
        publicCountry,
        publicCtaLink,
        publicCtaText,
        publicCtaType,
        publicGroupedByType,
        publicHasFrequencySwitcher,
        publicPresentationId,
        publicTitle,
        publicVideo,
      ],
    ),
  };
};
