import { config } from 'config';

import { blog } from 'types';

import { $Object } from 'libs/object/object.types';

import { Feature } from 'ui/molecules/Features/Features';

import { fetcher, Params } from '../../fetcher';
import { Page } from '../common/common.types';
import type {
  Author,
  Benefits,
  Career,
  CareerBenefit,
  Customer,
  CustomerList,
  Demo,
  Faq,
  Ranks,
  Review,
  Roadmap,
  Team,
  WebTechnologies,
  WebTechnology,
  Product,
  ProductMap,
  Config,
  Presentation,
  Agent,
} from './content.types';

export const content: $Object<(params: Params) => Promise<any>> = {
  config: (params: Params) =>
    fetcher
      .get<Config[]>(`/content-type/delivery/global_config/list/`, {
        depth: 0,
        ...params,
      })
      .then(({ data }) => ({ data: data?.[0] })),

  pageVars: ({ template, ...params }: Params) =>
    fetcher.get<{ [key: string]: string }[]>(`/content-type/delivery/page_var_${template}/list/`, {
      depth: -1,
      ...params,
    }),

  author: ({ id, ...params }: Params) => fetcher.get<Author>(`/content-type/delivery/author/by-id/${id}/`, params),

  articlesList: ({ current_type: currentType, type, id, case_studies_type: caseStudiesType, ...params }: Params) => {
    const newParams = {} as $Object<any>;

    if (type === 'related') {
      newParams.page_size = config.articles.relatedPerPage;

      if (currentType && Boolean(caseStudiesType) === false) {
        newParams['filter[has_article.type]'] = currentType;
      }
    }

    if (type === 'by_author') {
      newParams.depth = 1;
      newParams['filter[has_article.has_author]'] = id;
    }

    if (type && blog.includes(type)) {
      newParams['filter[has_article.type]'] = type;
    }

    if (caseStudiesType !== undefined) {
      /** Show only case study articles */
      if (caseStudiesType !== '') {
        newParams['filter[has_article.case_studies_type]'] = caseStudiesType;
      } else {
        newParams['filter[has_article.case_studies_type__in]'] = 'category_1,category_2,category_3';
      }
    } else {
      /** Show all unless case study */
      newParams['filter[has_article.case_studies_type]'] = null;
    }

    return fetcher.get<Page[]>(`/content-type/delivery/pages/list/`, {
      depth: 2,
      page_size: id ? config.articles.relatedPerPage : config.articles.regularPerPage,
      sort: '-created_at',
      'filter[slug__ne]': null,
      'filter[has_article__ne]': null,
      fields: [
        'title',
        'background',
        'description',
        'slug',
        'has_article.type',
        'has_article.case_studies_type',
        'has_article.read_time',
      ].join(','),
      ...params,
      ...newParams,
    });
  },

  customersList: (params: Params) =>
    fetcher.get<CustomerList[]>('/content-type/delivery/customers_list/list/', {
      depth: -1,
      'filter[image__ne]': null,
      ...params,
    }),

  ranks: (params: Params) =>
    fetcher.get<Ranks[]>('/content-type/delivery/ranks/list/', {
      depth: -1,
      ...params,
    }),

  requestDemo: (params: Params) =>
    fetcher.get<Demo[]>('/content-type/delivery/request_demo/list/', {
      depth: -1,
      ...params,
    }),

  review: (params: Params) =>
    fetcher.get<Review[]>('/content-type/delivery/review/list/', {
      depth: -1,
      ...params,
    }),

  ourBenefits: (params: Params) =>
    fetcher.get<Benefits>('/content-type/delivery/our_benefits/list/', {
      depth: -1,
      ...params,
    }),

  roadmap: (params: Params) =>
    fetcher.get<Roadmap[]>('/content-type/delivery/roadmap/list/', {
      depth: -1,
      ...params,
    }),

  ourTeam: (params: Params) =>
    fetcher.get<Team[]>('/content-type/delivery/our_team/list/', {
      depth: -1,
      ...params,
    }),

  customersFeedback: (params: Params) =>
    fetcher.get<Customer[]>('/content-type/delivery/customers_feedback/list/', {
      depth: -1,
      ...params,
    }),

  features: (params: Params) =>
    fetcher.get<Feature[]>('/content-type/delivery/features/list/', {
      depth: -1,
      ...params,
    }),

  webTechnologies: (params: Params) =>
    fetcher.get<WebTechnologies[]>('/content-type/delivery/web_technologies/list/', {
      ...params,
      depth: -1,
      sort: 'position',
      page_size: 2000,
    }),

  webTechnology: (params: Params) =>
    fetcher.get<WebTechnology[]>('/content-type/delivery/web_technology/list/', {
      ...params,
      depth: 0,
      fields: ['id', 'parent', 'title'].join(','),

      /**
       * it is a static endpoint with fixed amount of items (1055)
       * we divide them in 3 separate requests
       */
      page_size: 352,
    }),

  faq: ({ page, variant, ...params }: Params) =>
    fetcher.get<Faq[]>('/content-type/delivery/frequency_questions/list/', {
      ...params,
      depth: 0,
      sort: 'position',
      'filter[page]': page || null,
      'filter[variant]': variant || null,
      'filter[question__ne]': null,
      'filter[answer__ne]': null,
    }),

  careers: ({ category, ...params }: Params) => {
    const newParams = {} as $Object<any>;
    if (category) {
      newParams['filter[category]'] = category;
    }

    return fetcher.get<Career[]>('/content-type/delivery/careers/list/', {
      depth: 0,
      ...params,
      ...newParams,
    });
  },

  careerBenefits: (params: Params) =>
    fetcher.get<CareerBenefit[]>('/content-type/delivery/career_benefits/list/', {
      ...params,
      depth: 0,
    }),

  products: ({ country = '', standalone = false, ...params }: Params) =>
    fetcher.get<Product[]>('/content-type/delivery/pricing_card/list/', {
      ...params,
      depth: 3,
      sort: 'position',
      'filter[country]': country,
      'filter[standalone]': standalone ? true : null,
    }),

  productMapTo: ({ country = '', ...params }: Params) =>
    fetcher.get<ProductMap[]>('/content-type/delivery/pricing_country_map/list/', {
      ...params,
      depth: 0,
      page_size: 1,
      'filter[country]': country,
    }),

  academyFaculties: ({ slug, ...params }: Params) => {
    return fetcher.get<Page[]>(`/content-type/delivery/pages/list/`, {
      depth: 1,
      page_size: slug ? 3 : 25,
      sort: '-created_at',
      'filter[slug__ne]': slug || undefined,
      'filter[has_academy_faculty__ne]': null,
      ...params,
    });
  },

  presentation: ({ id = 'aggregator', ...params }: Params) =>
    fetcher.get<Presentation[]>('/content-type/delivery/presentation/list/', {
      ...params,
      depth: 0,
      page_size: 1,
      'filter[id]': id,
    }),

  agents: (params: Params) =>
    fetcher.get<Agent[]>('/content-type/delivery/ai_agent/list/', { ...params, depth: -1, page_size: 100 }),
};
