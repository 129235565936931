import { ReactNode } from 'react';

import cn from 'classnames';

import styles from './HeroText.module.scss';

interface Props {
  size?: 'sm' | 'md' | 'lg';
  align?: 'center' | 'left' | 'right';
  tagName?: 'div' | 'h1' | 'h2' | 'p';
  font?: 5 | 4 | 3 | 2 | 1;
  className?: string;
  width?: number;
  children?: ReactNode;
}

export const HeroText = ({
  tagName: Component = 'h2',
  font = 4,
  size = 'md',
  align = 'center',
  className = '',
  width,
  children,
}: Props) => {
  return (
    <Component
      className={cn(styles['hero-text'], styles[`hero-text-${size}`], styles[`font-${font}`], styles[align], className)}
      style={width ? { width: '100%', maxWidth: width } : undefined}
    >
      {children}
    </Component>
  );
};
