import styles from './AuthTemplateSkeleton.module.scss';

const baseClass = 'auth-template-skeleton';

export const AuthTemplateSkeleton = () => {
  return (
    <div className={styles[`${baseClass}-wrapper`]}>
      <h2 className={styles[`${baseClass}-title`]}>Account Skeleton</h2>

      <div className={styles[`${baseClass}-form`]}>
        <div className={styles[`${baseClass}-item`]} />
        <div className={styles[`${baseClass}-item`]} />
        <div className={styles[`${baseClass}-item`]} />
      </div>

      <span className={styles[`${baseClass}-link`]}>Auth templates account skeleton</span>
    </div>
  );
};
