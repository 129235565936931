import styles from './AgentTemplateSkeleton.module.scss';

const baseClass = 'agent-template-skeleton';

export const AgentTemplateSkeleton = () => {
  return (
    <div className={styles[`${baseClass}-wrapper`]}>
      <h2 className={styles[`${baseClass}-title`]}>This book demo template skeleton</h2>

      <div className={styles[`${baseClass}-item`]}/>

      <span className={styles[`${baseClass}-link`]}>By submitting book-demo this form account skeleton</span>

      <div className={styles[`${baseClass}-cards`]}>
        <div className={styles[`${baseClass}-card`]}/>
        <div className={styles[`${baseClass}-card`]}/>
        <div className={styles[`${baseClass}-card`]}/>
      </div>
    </div>
  );
};
