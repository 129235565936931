import dynamic from 'next/dynamic';

import { DynamicImport } from 'ui/DynamicImport';

import { AgentsTemplate } from 'features/agents/templates/AgentsTemplate/AgentsTemplate';
import { AuthTemplateSkeleton } from "features/agents/templates/AuthTemplate/AuthTemplateSkeleton";
import { BookDemoTemplateSkeleton } from "features/agents/templates/BookDemoTemplate/BookDemoTemplateSkeleton";
import {
  ForgotPasswordTemplateSkeleton
} from "features/agents/templates/ForgotPasswordTemplate/ForgotPasswordTemplateSkeleton";
import {
  ConfirmPasswordTemplateSkeleton
} from "features/agents/templates/ConfirmPasswordTemplate/ConfirmPasswordTemplateSkeleton";
import { AgentTemplateSkeleton } from "features/agents/templates/AgentTemplate/AgentTemplateSkeleton";

const authTemplate = () => (
  <DynamicImport fallback={() => <AuthTemplateSkeleton />}>
    {() => {
      const Component = dynamic(
        () => import('features/agents/templates/AuthTemplate/AuthTemplate').then((module) => module.AuthTemplate),
        {
          ssr: false,
          loading: () => <AuthTemplateSkeleton />,
        },
      );

      return <Component />;
    }}
  </DynamicImport>
);

export const templates = {
  agents: () => <AgentsTemplate />,

  agent: () => (
    <DynamicImport fallback={() => <AgentTemplateSkeleton />}>
      {() => {
        const Component = dynamic(
          () => import('features/agents/templates/AgentTemplate/AgentTemplate').then((module) => module.AgentTemplate),
          {
            ssr: false,
            loading: () => <AgentTemplateSkeleton />,
          },
        );

        return <Component />;
      }}
    </DynamicImport>
  ),

  bookDemo: () => (
    <DynamicImport fallback={() => <BookDemoTemplateSkeleton />}>
      {() => {
        const Component = dynamic(
          () =>
            import('features/agents/templates/BookDemoTemplate/BookDemoTemplate').then(
              (module) => module.BookDemoTemplate,
            ),
          {
            ssr: false,
            loading: () => <BookDemoTemplateSkeleton />,
          },
        );

        return <Component />;
      }}
    </DynamicImport>
  ),

  login: authTemplate,
  register: authTemplate,

  confirmPassword: () => (
    <DynamicImport fallback={() => <ConfirmPasswordTemplateSkeleton />}>
      {() => {
        const Component = dynamic(
          () =>
            import('features/agents/templates/ConfirmPasswordTemplate/ConfirmPasswordTemplate').then(
              (module) => module.ConfirmPasswordTemplate,
            ),
          {
            ssr: false,
            loading: () => <ConfirmPasswordTemplateSkeleton />,
          },
        );

        return <Component />;
      }}
    </DynamicImport>
  ),

  forgotPassword: () => (
    <DynamicImport fallback={() => <ForgotPasswordTemplateSkeleton />}>
      {() => {
        const Component = dynamic(
          () =>
            import('features/agents/templates/ForgotPasswordTemplate/ForgotPasswordTemplate').then(
              (module) => module.ForgotPasswordTemplate,
            ),
          {
            ssr: false,
            loading: () => <ForgotPasswordTemplateSkeleton />,
          },
        );

        return <Component />;
      }}
    </DynamicImport>
  ),
};
