import styles from './BookDemoTemplateSkeleton.module.scss';

const baseClass = 'book-demo-template-skeleton';

export const BookDemoTemplateSkeleton = () => {
  return (
    <div className={styles[`${baseClass}-wrapper`]}>
      <h2 className={styles[`${baseClass}-title`]}>This book demo template skeleton for free demo yourself</h2>

      <div className={styles[`${baseClass}-form`]}>
        <div className={styles[`${baseClass}-item`]} />
        <div className={styles[`${baseClass}-item`]} />
        <div className={styles[`${baseClass}-item`]} />
      </div>

      <span className={styles[`${baseClass}-link`]}>By submitting book-demo this form account skeleton</span>
      <span className={styles[`${baseClass}-link`]}>Templates account skeleton</span>
    </div>
  );
};
