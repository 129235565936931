/* eslint react-hooks/exhaustive-deps: "warn" */

import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import { useRouter } from 'libs/router/useRouter';

import { LeadContext, type LeadData } from 'context/LeadContext';

import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

import { FormHubspotStringCss } from 'features/customers/organisms/FormHubspot/FormHubspotStringCss';

import styles from './FormHubspot.module.scss';

type SubmittedData = {
  redirectUrl: string;
  submissionValues: {
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
  };
};

type HubspotType = {
  forms: {
    create: (options: {
      region: string;
      portalId: string;
      formId: string;
      target: string;
      css?: string;
      onFormReady: () => void;
      onFormSubmitted?: (form: unknown, data: SubmittedData) => void;
      redirectUrl?: string;
      inlineMessage?: string;
    }) => void;
  };
};

export type FormHubspotProps = {
  data: Record<string, string> & { default: string };
  redirectUrl?: string;
  onFormSubmitted?: (leadData: LeadData) => void;
};

export const FormHubspot = ({ data, redirectUrl, onFormSubmitted }: FormHubspotProps) => {
  const { query } = useRouter();
  const { setLeadData } = useContext(LeadContext);
  const hbsptRef = useRef<HubspotType | null>(null);

  const [loading, setLoading] = useState(true);

  const formId = useMemo(() => {
    return data?.[query.lang as string] || data.default;

    // * This may fire form initialization if deps are not provided properly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.[query.lang as string], data.default]);

  useEffect(() => {
    const initializeForm = () => {
      if (!hbsptRef.current) {
        return;
      }

      const onFormSubmittedHandler = onFormSubmitted
        ? (_: unknown, submittedData: SubmittedData) => {
            if (!submittedData.submissionValues) {
              return;
            }

            setLoading(true);

            const fullName = [submittedData.submissionValues.firstname, submittedData.submissionValues.lastname]
              .filter((i) => i)
              .join(' ');

            setLeadData({
              full_name: fullName,
              phone: submittedData.submissionValues.phone,
              email: submittedData.submissionValues.email,
              message: '',
            });

            onFormSubmitted({
              full_name: fullName,
              phone: submittedData.submissionValues.phone,
              email: submittedData.submissionValues.email,
              message: '',
            });
          }
        : undefined;

      hbsptRef.current.forms.create({
        region: 'eu1',
        portalId: '144159233',
        formId,
        target: '#hubspotForm',
        css: FormHubspotStringCss,
        onFormReady: () => setLoading(false),
        onFormSubmitted: onFormSubmittedHandler,
        redirectUrl: onFormSubmittedHandler ? undefined : redirectUrl,
        inlineMessage: onFormSubmittedHandler ? 'Thank you for submission. Loading next step ...' : undefined,
      });
    };

    if (!hbsptRef.current) {
      const script = document.createElement('script');

      script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
      script.async = true;
      script.onload = () => {
        hbsptRef.current = (window as unknown as { hbspt?: HubspotType }).hbspt ?? null;
        initializeForm();
      };

      document.body.appendChild(script);
    } else {
      initializeForm();
    }

    return () => {
      const formWrapper = document.getElementById('hubspotForm');

      if (formWrapper) {
        formWrapper.innerHTML = '';
      }
    };
  }, [formId, onFormSubmitted, redirectUrl, setLeadData]);

  return (
    <>
      <div className={cn(styles['hubspot-form-loading'], !loading && styles['hidden'])}>
        <LoaderSpin />
      </div>

      <div className={cn(styles['hubspot-form-wrapper'], loading && styles['hidden'])}>
        <div id="hubspotForm" className={styles['hubspot-form']} />
      </div>
    </>
  );
};
