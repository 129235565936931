/* eslint react-hooks/exhaustive-deps: "warn" */

import React, { createContext, ReactNode, useState, useEffect, useRef, useCallback } from 'react';

import { isObject, keys } from 'libs/object/object';
import { getLocalStorage, setLocalStorage } from 'libs/localStorage';
import { clearTimeoutIfExists } from 'libs/node';

export const initialValues = {
  full_name: '',
  email: '',
  phone: '',
  message: '',
};

type Optional<T extends object> = {
  [K in keyof T]?: T[K];
};

export type LeadData = typeof initialValues;

type LeadContextType = {
  leadData: LeadData;
  setLeadData: (value: Optional<LeadData>) => void;
};

export const LeadContext = createContext<LeadContextType>({ leadData: initialValues, setLeadData: () => {} });

export const LeadProvider = ({ children }: { children: ReactNode }) => {
  const debounce = useRef<NodeJS.Timeout | undefined>();

  const [leadData, setLeadData] = useState(initialValues);

  useEffect(() => {
    const savedData = getLocalStorage('lead-data') as LeadData;

    if (isObject(savedData)) {
      try {
        setLeadData((prevState) => ({
          ...prevState,
          ...keys(savedData).reduce((acc, current) => {
            if (current in initialValues) {
              acc[current] = savedData[current];
            }

            return acc;
          }, {} as LeadData),
        }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error parsing lead data from localStorage:', error);
      }
    }
  }, []);

  const setLeadDataWithLocalStorage = useCallback((data: Optional<LeadData>) => {
    if (debounce.current) {
      clearTimeoutIfExists(debounce.current);
    }

    setLeadData((prevState) => {
      const newState = { ...prevState, ...data };

      debounce.current = setTimeout(() => setLocalStorage('lead-data', newState), 1000);

      return newState;
    });
  }, []);

  return (
    <LeadContext.Provider
      value={{
        leadData,
        setLeadData: setLeadDataWithLocalStorage,
      }}
    >
      {children}
    </LeadContext.Provider>
  );
};
