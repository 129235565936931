import styles from './ForgotPasswordTemplateSkeleton.module.scss';

const baseClass = 'forgot-password-template-skeleton';

export const ForgotPasswordTemplateSkeleton = () => {
  return (
    <div className={styles[`${baseClass}-wrapper`]}>
      <h2 className={styles[`${baseClass}-title`]}>Skeleton forgot password temp</h2>

      <div className={styles[`${baseClass}-form`]}>
        <div className={styles[`${baseClass}-item`]} />
        <div className={styles[`${baseClass}-item`]} />
      </div>

      <span className={styles[`${baseClass}-link`]}>By submitting this form account skeleton</span>
    </div>
  );
};
