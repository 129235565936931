import { useContext, useMemo, useRef } from 'react';

import cn from 'classnames';

import { ContentContext } from 'context/ContentContext';

import { useIntersection } from 'hooks/useIntersection';
import { useImage } from 'hooks/useImage';

import { Button } from 'ui/atoms/Button/Button';

interface Type {
  standalone?: boolean;
  size?: 'regular' | 'compact';
  type?: string;
  className?: string;
  title?: string;
  button?: string;
  alternative?: boolean;
  hasCountryPromotion?: boolean;
}

export const RequestDemo = ({
  standalone = false,
  size = 'regular',
  type = '',
  className = '',
  alternative,
  hasCountryPromotion,
  title,
  button,
}: Type) => {
  const ref = useRef<HTMLDivElement>(null);
  const intersected = useIntersection(ref);

  const { buildUrl } = useImage();
  const { secondaryNavigation, additionals, pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  const requestDemo = useMemo(() => {
    const [data] = additionals.requestDemo?.data || [];

    return data;
  }, [additionals.requestDemo]);

  if (!(title || commonPageVars.book_demo_title)) {
    return null;
  }

  // Either render it if page has checkbox checked or it's standalone
  if (!standalone && !requestDemo?.show) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cn(
        'request-demo',
        `request-demo-size-${size}`,
        className,
        type,
        alternative && 'alternative',
        hasCountryPromotion && 'has-country-promotion',
      )}
      style={{
        backgroundImage: intersected
          ? `url('${buildUrl({
              src: `img/request${type === 'gray' ? '-gray' : ''}.jpg`,
              width: 1216,
              height: 360,
            })}')`
          : undefined,
      }}
    >
      <div className="request-demo__wrapper">
        <div className="request-demo__title">
          <h2>{title || commonPageVars.book_demo_title}</h2>
        </div>

        {secondaryNavigation.book_demo?.slug && (
          <div className="request-demo__button">
            <Button type="transparent" size={size === 'compact' ? 48 : 64} route={secondaryNavigation.book_demo.slug}>
              {button || commonPageVars.book_demo_button}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default RequestDemo;
