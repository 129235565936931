/* eslint react-hooks/exhaustive-deps: "warn" */
import type { ReactNode } from 'react';

import cn from 'classnames';

import styles from './ButtonGroup.module.scss';

const baseClass = 'gdb-button-group';

type Props = {
  className?: string;
  children: ReactNode;
};

export const ButtonGroup = ({ className, children }: Props) => {
  return <div className={cn(styles[baseClass], className)}>{children}</div>;
};
