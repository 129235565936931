/* eslint react-hooks/exhaustive-deps: "warn" */

import { useState, useEffect, ReactNode } from 'react';

import cn from 'classnames';

import { clearTimeoutIfExists } from 'libs/node';

import { InfinityLoader } from 'ui/atoms/InfinityLoader/InfinityLoader';

import styles from './SmoothLoading.module.scss';

type Props = {
  loading: boolean;
  children: ReactNode;
  hasInitialLoading?: boolean;
  hasShadow?: boolean;
  hasSpinner?: boolean;
  spinnerType?: string;
  height?: string | number;
};

export const SmoothLoading = ({
  loading,
  children,
  hasInitialLoading = true,
  hasShadow = true,
  hasSpinner = false,
  spinnerType = '',
  height,
}: Props) => {
  const [showContent, setShowContent] = useState(!hasInitialLoading || false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (loading === false && hasInitialLoading) {
      timer = setTimeout(() => {
        setShowContent(true);
      }, 300);
    }

    return () => {
      clearTimeoutIfExists(timer);
    };
  }, [loading, hasInitialLoading]);

  return (
    <div
      className={cn(styles['loading'], showContent && styles['loading-show'])}
      style={showContent === false && height ? { height } : {}}
    >
      {hasSpinner && showContent === false && (
        <InfinityLoader hasShadow={hasShadow} className={styles['loading-spinner']} type={spinnerType} />
      )}

      <div className={styles['loading-children']}>{children}</div>
    </div>
  );
};
