/* eslint react-hooks/exhaustive-deps: "warn" */

import { useContext } from 'react';

import { ContentContext } from 'context/ContentContext';

import { InfoBlock } from 'ui/molecules/InfoBlock/InfoBlock';

import { CardsAgents } from 'features/agents/organisms/CardsAgents/CardsAgents';

import styles from './AgentsTemplate.module.scss';

const baseClass = 'agents';

export const AgentsTemplate = () => {
  const { data } = useContext(ContentContext);

  return (
    <div className={styles[`${baseClass}-wrapper`]}>
      <InfoBlock title={data.title} description={data.description} buttonText={data.book_demo_button} />

      <CardsAgents />
    </div>
  );
};
