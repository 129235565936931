/* eslint react-hooks/exhaustive-deps: "warn" */

import cn from 'classnames';

import { Button } from 'ui/atoms/Button/Button';
import { Icon } from 'ui/atoms/Icon/Icon';

import { useAgentsContext } from 'features/agents/helpers/AgentsContext/AgentsContext';
import { AgentData, TemplatesType } from 'features/agents/helpers/AgentsContext/AgentsContext.types';
import { isFreeType, isPaidType } from 'features/agents/helpers/utils';

import styles from './CardsAgents.module.scss';

const baseClass = 'cards-agents';

const iconTypes = {
  check_company_status: 'status-check',
  check_company_registration_number: 'registration',
  check_vat_number: 'vat-number',
  find_company_lei: 'lei',
  retrieve_company_sic_code: 'sic-code',
  competitor_analysis: 'competitor',
  lead_prospector: 'prospector',
  business_credit_score_check: 'score',
  credit_limit_advisor: 'credit-limit',
  check_company_financials: 'financials',
  check_company_shareholders: 'shareholders',
  identify_beneficial_owners: 'owner',
  business_activity_monitor: 'monitoring',
  director_check: 'director',
  supply_chain_risk_score: 'risk',
  payment_behavior_analysis: 'analysis',
  email_finder: 'email',
  phone_number_finder: 'phone-nr',
  check_court_judgment_status: 'report',
  total_addressable_market_estimator: 'market-estimator',
  funding_history_tracker: 'funding-history',
  esg_score: 'esg-score',
  customer_sentiment_analysis_agent: 'customer-analysis',
  peps_and_sanction_agent: 'sanction',
  intellectual_property_agent: 'property',
  company_reputation_score_agent: 'reputation-score',
  supplier_verification_agent: 'verification',
  regulatory_compliance_check_agent: 'regulatory-compliance',
  cybersecurity_risk_assessment_agent: 'cybersecurity',
  ipo_monitor_agent: 'ipo-monitor',
  website_traffic_agent: 'website',
} as const;

type AgentName = keyof typeof iconTypes;

type Props = {
  className?: string;
};

type CardProps = {
  active: boolean;
  card: AgentData;
  onClick: (type: TemplatesType, id?: string) => void;
  type: TemplatesType;
};

// TODO: Add tooltip over the whole Card
// {card.description && <div dangerouslySetInnerHTML={{ __html: card.description }}></div>}
// {card.use_case && <div dangerouslySetInnerHTML={{ __html: card.use_case }}></div>}

const Card = ({ active, card, onClick, type }: CardProps) => {
  const cardIcon = card.name in iconTypes && `company-${iconTypes[card.name as AgentName]}`;

  const onClickHandler = !active
    ? () => {
        onClick(isPaidType(card.type) ? 'bookDemo' : 'agent', card.id);
      }
    : undefined;

  if (!cardIcon) {
    return null;
  }

  return (
    <li className={styles[`${baseClass}-card`]} onClick={onClickHandler}>
      <div className={styles[`${baseClass}-icon`]}>
        <Icon type={cardIcon} />
      </div>

      <div className={styles[`${baseClass}-inner`]}>
        <p className={styles[`${baseClass}-title`]}>{card.title}</p>

        <div className={styles[`${baseClass}-link`]}>
          <Button loadingDelay={0} size={32} type="invert" disabled={active}>
            {active ? (
              'Selected'
            ) : (
              <>
                Get
                <Icon type="company-arrow-right" className={styles[`${baseClass}-arrow`]} />
              </>
            )}
          </Button>
        </div>
      </div>

      {!active && type !== 'agents' && isFreeType(card.type) && (
        <div className={styles[`${baseClass}-type`]}>{card.type}</div>
      )}
    </li>
  );
};

export const CardsAgents = ({ className }: Props) => {
  const { data, updateType, type, agent } = useAgentsContext();

  const renderList = (cards: any[], title?: string) => (
    <>
      {title && <h2 className={styles[`${baseClass}-header-title`]}>{title}</h2>}

      <ul className={cn(styles[`${baseClass}-cards`], className)}>
        {cards.map((card) => (
          <Card key={card.id} active={card.id === agent} card={card} onClick={updateType} type={type} />
        ))}
      </ul>
    </>
  );

  if (type === 'agents') {
    const [freeCards, paidCards] = data.reduce<[AgentData[], AgentData[]]>(
      (acc, curr) => {
        if (isFreeType(curr.type)) {
          acc[0].push(curr);
        } else if (isPaidType(curr.type)) {
          acc[1].push(curr);
        }

        return acc;
      },
      [[], []],
    );

    return (
      <>
        {renderList(freeCards, 'Free')}
        {renderList(paidCards, 'Paid')}
      </>
    );
  }

  return renderList(data);
};
