import { type FormEvent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import cn from 'classnames';
import dynamic from 'next/dynamic';

import { clearTimeoutIfExists } from 'libs/node';
import { filterOptions } from 'libs/string';

import { ContentContext } from 'context/ContentContext';

import { Input } from 'ui/atoms/Input/Input';
import { ErrorMessage } from 'ui/atoms/ErrorMessage/ErrorMessage';
import { Button } from 'ui/atoms/Button/Button';
import { DynamicImport } from 'ui/DynamicImport';
import { SelectLoader } from 'ui/atoms/Select/SelectLoader';

import styles from './CreditReportForm.module.scss';

const advancedCountries = ['US', 'CN', 'CA'];

export interface CountryOption {
  value: string;
  title: string;
  countryId: number;
  research: boolean;
  freshInvestigation: number | boolean;
}

interface Props {
  countryOptions: CountryOption[];
  countryLoading?: boolean;
  stateOptions?: any[];
  formData: Record<string, any>;
  errorsCredits?: { [key: string]: string | string[] };
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onChangeState: (target: string) => (value: any) => void;
  loadingStates?: boolean;
  hasStates?: boolean;
  size?: 64 | 48 | 32;
}

export const CreditReportForm = ({
  countryOptions,
  countryLoading,
  stateOptions = [],
  formData,
  errorsCredits,
  onSubmit,
  onChangeState,
  loadingStates = false,
  hasStates = false,
  size = 64,
}: Props) => {
  const { pageVars } = useContext(ContentContext);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const buttonFocusTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      clearTimeoutIfExists(buttonFocusTimer.current);
    };
  }, []);

  const onChangeCountry = useCallback(
    (value: string) => {
      onChangeState('country')(value);

      buttonFocusTimer.current = setTimeout(() => {
        buttonRef?.current?.focus();
      }, 100);
    },
    [onChangeState],
  );

  const creditReportPageVars = useMemo(() => pageVars.credit_report || {}, [pageVars.credit_report]);

  return (
    <form onSubmit={onSubmit} className={cn(styles['credit-report'], styles[`credit-report-size-${size}`])}>
      <div
        className={cn(
          styles['credit-report-wrapper'],
          advancedCountries.includes(formData.country) && hasStates && styles['advanced'],
        )}
      >
        <Input
          ariaLabel={creditReportPageVars.pricing_input_placeholder}
          text={creditReportPageVars.pricing_input_placeholder || 'Enter company name or reg. number'}
          dataTestId="form-credit-block"
          value={formData.company}
          onChange={onChangeState('company')}
          error={errorsCredits?.company}
          size={size}
        />

        <div className={styles['select-credit-wrapper']}>
          <DynamicImport
            fallback={() => <SelectLoader title={creditReportPageVars.pricing_select_placeholder || 'Country'} />}
          >
            {() => {
              const Component = dynamic(() => import('ui/atoms/Select/Select'), {
                ssr: false,
                loading: () => <SelectLoader title={creditReportPageVars.pricing_select_placeholder || 'Country'} />,
              });

              return (
                <Component
                  options={countryOptions}
                  value={formData.country}
                  onChange={(value) => onChangeCountry(value as string)}
                  placeholder={creditReportPageVars.pricing_select_placeholder || 'Country'}
                  filterOptions={filterOptions}
                  className={styles['select-credit']}
                  size={size}
                  disabled={countryLoading}
                />
              );
            }}
          </DynamicImport>

          {hasStates ? (
            <>
              <DynamicImport
                fallback={() => <SelectLoader title={creditReportPageVars.additional_state_placeholder} />}
              >
                {() => {
                  const Component = dynamic(() => import('ui/atoms/Select/Select'), {
                    ssr: false,
                    loading: () => <SelectLoader title={creditReportPageVars.additional_state_placeholder} />,
                  });

                  return (
                    <Component
                      hasStateOptions
                      options={stateOptions}
                      value={formData.countryState}
                      placeholder={creditReportPageVars.additional_state_placeholder}
                      onChange={onChangeState('countryState')}
                      filterOptions={filterOptions}
                      loading={loadingStates}
                      disabled={loadingStates}
                      size={size}
                    />
                  );
                }}
              </DynamicImport>

              <Input
                ariaLabel={creditReportPageVars.additional_city_placeholder}
                text={creditReportPageVars.additional_city_placeholder}
                value={formData.city}
                onChange={onChangeState('city')}
                size={size}
              />
            </>
          ) : null}

          {formData.alarmCountry && (
            <span className={styles['input-alert']} data-test="input-alert">
              {creditReportPageVars.alert}
            </span>
          )}
        </div>

        <Button ref={buttonRef} size={size} htmlType="submit">
          {creditReportPageVars.search_button_title || 'Search'}
        </Button>

        {errorsCredits?.detail || errorsCredits?.non_field_errors ? (
          <ErrorMessage error={errorsCredits.detail || errorsCredits.non_field_errors} position="right" />
        ) : null}
      </div>
    </form>
  );
};
