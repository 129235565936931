/* eslint react-hooks/exhaustive-deps: "warn" */

import { createContext, useMemo, useCallback, useContext, useEffect, useState } from 'react';

import type { CountryParams, SearchDataParams } from 'libs/http/api/dashboard/dashboard.types';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { clearTimeoutIfExists, getCookie, removeCookie } from 'libs/node';
import { useRouter } from 'libs/router/useRouter';

import { ContentContext } from 'context/ContentContext';

import { useRequest } from 'hooks/useRequest';

import type { AgentsContextProps, AgentsProviderProps, TemplatesType, AgentViewType } from './AgentsContext.types';
import { isFreeType } from '../utils';

const AgentsContext = createContext<AgentsContextProps | undefined>(undefined);

const getDashboardHeaders = () => ({
  Authorization: `Token ${getCookie('sso-token')}`,
  'Current-Application': 'ai_agent',
});

const getInitialAgentSearchValues = () => ({ company: '', country: '' });

const agentTypeKeyMap: Record<string, string> = {
  check_company_status: 'status',
  check_company_registration_number: 'reg_number',
  check_vat_number: 'vat_number',
  retrieve_company_sic_code: 'sic_code',
  // find_company_lei: undefined, // * Currently not available
};

export const AgentsProvider = ({ children }: AgentsProviderProps) => {
  const { query } = useRouter();
  const { additionals } = useContext(ContentContext);

  const [loading, setLoading] = useState(true);
  const [type, setType] = useState<TemplatesType>('agents');

  const [agent, setAgent] = useState<string>();
  const [agentViewType, setAgentViewType] = useState<AgentViewType>();
  const [agentSearchValues, setAgentSearchValues] = useState({ company: '', country: '' });

  const { request: requestCountries, loading: loadingCountries, data: dataCountries } = useRequest<CountryParams[]>();

  const {
    request: requestSearch,
    loading: loadingSearch,
    data: dataSearch,
    errors: errorsSearch,
  } = useRequest<SearchDataParams[]>();

  const {
    request: requestSelect,
    loading: loadingSelect,
    data: dataSelect,
    errors: errorsSelect,
  } = useRequest<Record<string, string>>({ data: {} });

  const hasGuardError = useMemo(
    () => Boolean(errorsSearch.error_guard || errorsSelect.error_guard),
    [errorsSearch.error_guard, errorsSelect.error_guard],
  );

  const verifySSOToken = useCallback(async () => {
    try {
      const ssoToken = getCookie('sso-token');

      if (ssoToken === null) {
        setType('register');

        return Promise.reject(false);
      }

      await dashboard.auth.details({ headers: { Authorization: `Token ${ssoToken}` } });

      return Promise.resolve(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      removeCookie('sso-token');
      setType('register');

      return Promise.reject(false);
    }
  }, []);

  const onClearAgentSearchValues = useCallback(() => {
    setAgentViewType(undefined);
    setAgentSearchValues(getInitialAgentSearchValues());
  }, []);

  const onChangeAgentSearchValues = useCallback(
    (key: string, value: string) => setAgentSearchValues((prevState) => ({ ...prevState, [key]: value })),
    [],
  );

  useEffect(() => {
    if (!query.ai_agent || !query.uid || !query.token) {
      setLoading(false);

      return;
    }

    (async () => {
      setLoading(true);

      try {
        await verifySSOToken();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        setType('confirmPassword');
      } finally {
        setLoading(false);
      }
    })();
  }, [query.ai_agent, query.uid, query.token, verifySSOToken]);

  useEffect(() => {
    if (agent) {
      onClearAgentSearchValues();
    }
  }, [agent, onClearAgentSearchValues]);

  useEffect(() => {
    if (typeof query.ai_agent !== 'string') {
      return;
    }

    const aiAgent = query.ai_agent;

    setLoading(true);

    (async () => {
      try {
        await verifySSOToken();

        setAgent(aiAgent);
        setType('agent');
      } finally {
        setLoading(false);
      }
    })();
  }, [additionals.agents?.data, query.ai_agent, verifySSOToken]);

  useEffect(() => {
    const debounce = setTimeout(
      () => {
        requestCountries(
          dashboard.agents.countries({
            type: 'country',
            lang: `${query.lang}`,
            headers: getDashboardHeaders(),
          }),
        );
      },
      type === 'agent' ? 0 : 10000,
    );

    return () => {
      clearTimeoutIfExists(debounce);
    };
  }, [type, query.lang, requestCountries]);

  const updateType = useCallback(
    async (newType: TemplatesType, id?: string) => {
      setAgent(id);

      if (['login', 'register'].includes(newType)) {
        setType(newType);

        return;
      }

      if (newType === 'agent') {
        const ssoToken = getCookie('sso-token');

        if (id) {
          const url = new URL(window.location.href);
          url.searchParams.set('ai_agent', id);
          window.history.pushState({}, '', url);
        }

        if (ssoToken === null) {
          setType('register');

          return;
        }

        await verifySSOToken();

        setType(newType);

        return;
      }

      setType(newType);
    },
    [verifySSOToken],
  );

  const select = useCallback(
    ({ id }: { id: string }) => {
      setAgentViewType('select');

      if (!additionals.agents?.data.length) {
        return;
      }

      const currentAgent = additionals.agents?.data.find((item) => isFreeType(item.type) && item.id === agent);

      if (!currentAgent) {
        return;
      }

      requestSelect(
        dashboard.agents.company({
          id,
          type: agentTypeKeyMap[currentAgent.name],
          headers: getDashboardHeaders(),
        }),
      );
    },
    [additionals.agents?.data, agent, requestSelect],
  );

  const search = useCallback(() => {
    if (agentSearchValues.company.trim().length === 0) {
      return;
    }

    setAgentViewType('search');

    const currentAgent = additionals.agents?.data.find((item) => isFreeType(item.type) && item.id === agent);

    if (!currentAgent) {
      return;
    }

    requestSearch(
      dashboard.agents.search({
        title: agentSearchValues.company,
        location_countries: [agentSearchValues.country],
        type: agentTypeKeyMap[currentAgent.name],
        headers: getDashboardHeaders(),
      }),
    );
  }, [additionals.agents?.data, agent, agentSearchValues.company, agentSearchValues.country, requestSearch]);

  return (
    <AgentsContext.Provider
      value={{
        data: additionals.agents?.data || [],
        type,
        updateType,
        agent,
        loading,

        hasGuardError,

        dataCountries,
        loadingCountries,

        agentViewType,

        agentSearchValues,
        onClearAgentSearchValues,
        onChangeAgentSearchValues,

        select,
        dataSelect,
        loadingSelect,
        errorsSelect,

        search,
        dataSearch,
        loadingSearch,
        errorsSearch,
      }}
    >
      {children}
    </AgentsContext.Provider>
  );
};

export const useAgentsContext = (): AgentsContextProps => {
  const context = useContext(AgentsContext);

  if (!context) {
    throw new Error('useAgentsContext must be used within an AgentsProvider');
  }

  return context;
};
