import cn from 'classnames';

import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

const baseClass = 'infinity-report-loader';

export const InfinityLoader = ({ className = '', type = '', hasShadow = true }) => (
  <div className={cn(baseClass, hasShadow && `${baseClass}-has-shadow`, className, type)}>
    <LoaderSpin />
  </div>
);
