import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import { config } from 'config';

import type { DataList, ListDataUpload } from 'libs/http/api/dashboard/dashboard.types';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { useRouter } from 'libs/router/useRouter';
import { clearTimeoutIfExists } from 'libs/node';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from 'libs/localStorage';
import { parseWebsite } from 'libs/string';

import { LeadContext } from 'context/LeadContext';

import { useRequest } from 'hooks/useRequest';

import { Columns, Table } from 'ui/organisms/Table/Table';
import { Status } from 'ui/atoms/Status/Status';
import { Icon } from 'ui/atoms/Icon/Icon';
import { Button } from 'ui/atoms/Button/Button';
import { Tooltip } from 'ui/atoms/Tooltip/Tooltip';

import styles from './CompaniesTable.module.scss';

import { ActionModal } from '../ActionModal/ActionModal';

const { blurThreshold } = config.enrichment;

type RenderColumnParams = {
  hasLoading?: boolean;
  className?: string;
  iconType?: string;
  render?: (label: string) => ReactNode;
};

type Props = {
  data?: DataList[];
  upload?: ListDataUpload;
  loading?: boolean;
};

export const CompaniesTable = ({ data = [], upload = {} as ListDataUpload, loading = false }: Props) => {
  const { query } = useRouter();
  const loadingModalTimer = useRef<NodeJS.Timeout>();
  const scrollTop = useRef<null | HTMLDivElement>(null);
  const { leadData } = useContext(LeadContext);
  const scrollDebounce = useRef<NodeJS.Timeout>();

  const { request: requestEnrich, loading: loadingEnrich, errors: errorsEnrich } = useRequest();

  const [showAllCompany, setShowAllCompany] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({} as { title: string; description: ReactNode });
  const [sampleDisabled, setSampleDisabled] = useState(false);

  useEffect(() => {
    (() => {
      if (getLocalStorage('enrichment_session') === getLocalStorage('sample_session')) {
        setSampleDisabled(true);

        return;
      }

      removeLocalStorage('sample_session');
    })();

    return () => {
      clearTimeoutIfExists(scrollDebounce.current);
    };
  }, []);

  const onToggleModal = () => setShowModal((s) => !s);

  const onContentModal = (title: string, description: ReactNode, type?: string) => () => {
    setLoadingModal(true);

    if (type === 'sample') {
      sendEnrichmentSample();
    }

    loadingModalTimer.current = setTimeout(() => {
      setLoadingModal(false);
    }, 1300);

    setModalContent({ title, description });
    onToggleModal();
  };

  const sendEnrichmentSample = async () => {
    const session = getLocalStorage('enrichment_session');

    if (session === null) {
      return;
    }

    await requestEnrich(
      dashboard.book({
        full_name: leadData.full_name,
        email: leadData.email,
        phone: leadData.phone,
        message: `User ${leadData.full_name} requested download sample for enrichment`,
        type: 'enrichment',
        session_key: session,
        lang: `${query.lang}`,
      }),
    );

    setLocalStorage('sample_session', session);
    setSampleDisabled(true);
  };

  const onShowAllResults = () => {
    clearTimeoutIfExists(scrollDebounce.current);

    scrollDebounce.current = setTimeout(() => {
      window.requestAnimationFrame(() => {
        if (showAllCompany && scrollTop.current) {
          const top = scrollTop.current.getBoundingClientRect().top + window.scrollY - 215;
          window.scrollTo({
            top,
            behavior: 'smooth',
          });
        }
      });
    }, 320);

    setShowAllCompany((s) => !s);
  };

  const hasOnlyEmail = useMemo(() => {
    return Boolean(upload?.headers?.length === 1 && upload.headers[0] === 'email');
  }, [upload]);

  const columnsWithData = useMemo(() => {
    const persistColumns = {
      index: true,
      score: true,
    };

    const getColumnsWithData = (item: { [key: string]: any }) => {
      const map = (value: typeof item) =>
        Object.keys(value).reduce<{ [key: string]: true }>((acc, current) => {
          if (value[current]) {
            acc[current] = true;
          }

          return acc;
        }, {});

      return {
        ...map(item.data || {}),
        ...map(item.current_result?.data || {}),
      };
    };

    return data.reduce(
      (acc, current) => ({
        ...acc,
        ...getColumnsWithData(current),
      }),
      { ...persistColumns },
    );
  }, [upload]);

  const columns = useMemo(() => {
    const renderColumn =
      ({ hasLoading = true, className = '', iconType = '', render }: RenderColumnParams = {}) =>
      (record: any, idx?: number) => {
        const label = Array.isArray(record) ? record.join(', ') : record;
        const hasLabel = Boolean(label);
        const renderedLabel = typeof render === 'function' ? render(label) : label;

        if (loading && ((hasLoading === false && hasLabel === false) || hasLoading)) {
          /**
           * Show skeleton when it requires no loading and there is no data
           * Show skeleton when it is loading and requires loading
           * */
          return <div className={styles['skeleton']} />;
        }

        if (hasLabel === false) {
          return <span className="link-disabled">---</span>;
        }

        return (
          <Tooltip label={idx !== undefined && idx > 10 && blurThreshold < idx ? null : renderedLabel}>
            {iconType ? (
              <span className={cn(styles['with-icon'], className && styles[className])}>
                <Icon type={iconType} />
                <span className="w-100 ellipsis">{renderedLabel}</span>
              </span>
            ) : (
              <span className={cn('ellipsis', className && styles[className])}>{renderedLabel}</span>
            )}
          </Tooltip>
        );
      };

    const renderClientTitle = (title: string) => (
      <>
        {title}

        <span className={styles['link']}>(client info)</span>
      </>
    );

    const $columns: Columns<DataList> = [
      /** Default columns */
      {
        title: 'NR',
        dataIndex: 'index',
        fixed: 'left',
        width: 45,
        render: (_: unknown, idx) => <span>{idx}</span>,
      },

      /** Client data */
      {
        dataIndex: 'data.name',
        visible: upload.headers?.includes('name'),
        title: renderClientTitle('Company name'),
        fixed: 'left',
        width: 230,
        render: renderColumn({ hasLoading: false }),
      },
      {
        dataIndex: 'data.country',
        visible: upload.headers?.includes('country'),
        title: renderClientTitle('Country'),
        fixed: 'left',
        width: 200,
        render: renderColumn({ hasLoading: false }),
      },
      {
        dataIndex: 'data.email',
        visible: upload.headers?.includes('email'),
        title: renderClientTitle('Company Email'),
        fixed: 'left',
        width: 200,
        render: renderColumn({ hasLoading: false, className: 'column-lower' }),
      },
      {
        dataIndex: 'data.website',
        visible: upload.headers?.includes('website'),
        title: renderClientTitle('Website'),
        fixed: 'left',
        width: 200,
        render: renderColumn({ hasLoading: false, className: 'column-lower', iconType: 'contact-web' }),
      },
      {
        dataIndex: 'data.registration_number',
        visible: upload.headers?.includes('registration_number'),
        title: renderClientTitle('Reg. Number'),
        fixed: 'left',
        width: 200,
        render: renderColumn({ hasLoading: false }),
      },
      {
        dataIndex: 'data.vat',
        visible: upload.headers?.includes('vat'),
        title: renderClientTitle('VAT'),
        fixed: 'left',
        width: 200,
        render: renderColumn({ hasLoading: false }),
      },

      /** Global data */
      {
        title: 'Full name',
        visible: hasOnlyEmail,
        dataIndex: 'current_result.data.full_name',
        width: 220,
        render: renderColumn(),
      },
      {
        title: 'First name',
        visible: hasOnlyEmail,
        dataIndex: 'current_result.data.first_name',
        width: 220,
        render: renderColumn(),
      },
      {
        title: 'Last name',
        visible: hasOnlyEmail,
        dataIndex: 'current_result.data.last_name',
        width: 220,
        render: renderColumn(),
      },
      {
        title: 'Job function',
        visible: hasOnlyEmail,
        dataIndex: 'current_result.data.job_function',
        width: 220,
        render: renderColumn(),
      },
      {
        title: 'Department',
        visible: hasOnlyEmail,
        dataIndex: 'current_result.data.department',
        width: 220,
        render: renderColumn(),
      },
      {
        title: 'Seniority',
        visible: hasOnlyEmail,
        dataIndex: 'current_result.data.seniority_level',
        width: 220,
        render: renderColumn(),
      },
      {
        title: 'Contact email',
        visible: hasOnlyEmail,
        dataIndex: 'current_result.data.contact_email',
        width: 220,
        render: renderColumn({ className: 'column-lower' }),
      },
      {
        title: 'Contact phone',
        visible: hasOnlyEmail,
        dataIndex: 'current_result.data.contact_phone',
        width: 220,
        render: renderColumn(),
      },
      {
        title: 'Name',
        dataIndex: 'current_result.data.company_name',
        width: 220,
        render: renderColumn(),
      },
      {
        title: 'Status',
        dataIndex: 'current_result.data.status',
        width: 120,
        render: (record: unknown) => (
          <span className={loading ? styles['skeleton'] : ''}>
            {loading ? null : record ? <Status status={record as string} /> : '---'}
          </span>
        ),
      },
      {
        title: 'Company Phone',
        dataIndex: 'current_result.data.company_phone',
        width: 180,
        render: renderColumn(),
      },
      {
        title: 'Company Email',
        dataIndex: 'current_result.data.company_email',
        width: 220,
        render: renderColumn({ className: 'column-lower' }),
      },
      {
        title: 'Country',
        dataIndex: 'current_result.data.country',
        width: 150,
        render: renderColumn(),
      },
      {
        title: 'Country Code',
        dataIndex: 'current_result.data.country_code',
        width: 140,
        render: renderColumn(),
      },
      {
        title: 'City or State',
        dataIndex: 'current_result.data.city_or_state',
        width: 180,
        render: renderColumn(),
      },
      {
        title: 'Reg. Number',
        dataIndex: 'current_result.data.registration_number',
        width: 150,
        render: renderColumn(),
      },
      {
        title: 'VAT',
        dataIndex: 'current_result.data.vat',
        width: 150,
        render: renderColumn(),
      },
      {
        title: 'Legal Form',
        dataIndex: 'current_result.data.legal_form',
        width: 240,
        render: renderColumn(),
      },
      {
        title: 'SIC Code',
        dataIndex: 'current_result.data.sic_code',
        width: 150,
        render: renderColumn(),
      },
      {
        title: 'Sic Description',
        dataIndex: 'current_result.data.sic_description',
        width: 200,
        render: renderColumn(),
      },
      {
        title: 'Industry',
        dataIndex: 'current_result.data.industry',
        width: 200,
        render: renderColumn(),
      },
      {
        title: 'Year Established',
        dataIndex: 'current_result.data.year_established',
        width: 150,
        render: renderColumn(),
      },
      {
        title: 'Size range',
        dataIndex: 'current_result.data.size_range',
        width: 150,
        render: renderColumn(),
      },
      {
        title: 'Turnover',
        dataIndex: 'current_result.data.turnover',
        width: 150,
        render: renderColumn(),
      },
      {
        title: 'Website',
        dataIndex: 'current_result.data.company_website',
        width: 220,
        render: renderColumn({
          className: 'column-lower',
          iconType: 'contact-web',
          render: (text) => parseWebsite(text),
        }),
      },
      {
        title: 'Alexa Rank',
        dataIndex: 'current_result.data.alexa_rank',
        width: 150,
        render: renderColumn(),
      },
      {
        title: 'Monthly Visits',
        dataIndex: 'current_result.data.monthly_visits',
        width: 150,
        render: renderColumn(),
      },

      /** Default columns */
      {
        title: 'Score',
        dataIndex: 'current_result.score',
        width: 70,
        fixed: 'right',
        render: (text: unknown) =>
          typeof text === 'string' ? (
            <span
              className={cn(
                styles['column-score-data'],
                styles[`column-score-data-${text?.toLowerCase() || 'n-a'}`],
                loading && styles['skeleton'],
              )}
            >
              {loading ? null : text || 'N/A'}
            </span>
          ) : null,
      },
    ];

    if (loading) {
      return $columns;
    }

    /** When loading is over show only columns that have data in at least one row */
    return $columns.filter((i) => {
      const keys = i.dataIndex.split('.');
      return keys[keys.length - 1] in columnsWithData;
    });
  }, [columnsWithData, loading, upload]);

  return (
    <>
      {showModal && (
        <ActionModal
          onClose={onToggleModal}
          loading={loadingModal || loadingEnrich}
          title={modalContent.title}
          description={modalContent.description}
          errors={errorsEnrich}
        />
      )}

      <div className={styles['companies-table']} ref={scrollTop}>
        <Table
          title={loading ? 'Loading ..' : undefined}
          columns={columns}
          data={showAllCompany === false && data.length > blurThreshold ? data.slice(0, 10) : data}
          blurThreshold={blurThreshold}
          actionHeader={
            <>
              <div className={styles['companies-table-actions']}>
                <Button
                  type="transparent"
                  size={32}
                  className={styles['companies-table-action']}
                  onClick={onContentModal(
                    'Thank you!',
                    <span>We have receive your request and someone will contact you soon</span>,
                  )}
                >
                  Download All
                </Button>

                <Button
                  size={32}
                  className={styles['companies-table-action']}
                  onClick={onContentModal(
                    'Thank you!',
                    <>
                      <span>Please check your email</span>
                      <span className={styles['companies-table-email']}>{leadData.email}</span>
                    </>,
                    'sample',
                  )}
                  disabled={sampleDisabled}
                >
                  Download sample
                </Button>
              </div>
            </>
          }
        />

        {data.length > 10 && (
          <Button
            type="transparent"
            size={48}
            onClick={onShowAllResults}
            className={styles['companies-table-view-more']}
            disabled={loading}
          >
            {showAllCompany ? 'View less' : 'View more'}
          </Button>
        )}
      </div>
    </>
  );
};
