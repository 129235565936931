import styles from './ConfirmPasswordTemplateSkeleton.module.scss';

const baseClass = 'confirm-password-template-skeleton';

export const ConfirmPasswordTemplateSkeleton = () => {
  return (
    <div className={styles[`${baseClass}-wrapper`]}>
      <h2 className={styles[`${baseClass}-title`]}>Skeleton forgot</h2>
      <h2 className={styles[`${baseClass}-title`]}>Skeleton</h2>

      <div className={styles[`${baseClass}-form`]}>
        <div className={styles[`${baseClass}-item`]} />
        <div className={styles[`${baseClass}-item`]} />
        <div className={styles[`${baseClass}-item`]} />
      </div>
    </div>
  );
};
