import HomePage from 'features/home/pages/HomePage/HomePage';
import CountryPageAlternative from 'features/profile/pages/CountryPageAlternative/CountryPageAlternative';
import CountryPage from 'features/profile/pages/CountryPage/CountryPage';
import IndustryPage from 'features/profile/pages/IndustryPage/IndustryPage';
import CountryPromotionPage from 'features/profile/pages/CountryPromotion/CountryPromotionPage';
import ApiPage from 'features/products/pages/ApiPage/ApiPage';
import LeadGenerationPage from 'features/products/pages/LeadGenerationPage/LeadGenerationPage';
import InsightsPage from 'features/products/pages/InsightsPage/InsightsPage';
import SalesForcePage from 'features/products/pages/SalesForcePage/SalesForcePage';
import FreshInvestigationPage from 'features/products/pages/FreshInvestigationPage/FreshInvestigationPage';
import AboutUsPage from 'features/about/pages/AboutUsPage/AboutUsPage';
import OurDataPage from 'features/about/pages/OurDataPage/OurDataPage';
import CareersPage from 'features/about/pages/CareersPage/CareersPage';
import VacancyPage from 'features/about/pages/VacancyPage/VacancyPage';
import CustomersPage from 'features/customers/pages/CustomersPage/CustomersPage';
import PricingPage from 'features/customers/pages/PricingPage/PricingPage';
import BookDemoPage from 'features/customers/pages/BookDemoPage';
import ContactUsPage from 'features/customers/pages/ContactUsPage/ContactUsPage';
import ThankYouPage from 'features/customers/pages/ThankYouPage/ThankYouPage';
import ArticlePage from 'features/articles/pages/ArticlePage/ArticlePage';
import BlogPage from 'features/articles/pages/BlogPage/BlogPage';
import AuthorPage from 'features/articles/pages/AuthorPage/AuthorPage';
import PolicyPage from 'features/public/pages/PolicyPage/PolicyPage';
import ProductPricingPage from 'features/product-pricing/pages/ProductPricingPage/ProductPricingPage';
import PricingTrial from 'features/product-pricing/pages/PricingTrial/PricingTrial';
import AcademyPage from 'features/academy/pages/AcademyPage/AcademyPage';
import ProspectingPlatform from 'features/academy/pages/ProspectingPlatform/ProspectingPlatformPage';
import EnrichmentPage from 'features/enrichment/pages/EnrichmentPage/EnrichmentPage';
import MexicoPage from 'features/lead/pages/MexicoPage/MexicoPage';
import AgentsPage from 'features/agents/pages/AgentsPage/AgentsPage';

export const templates: { [key: string]: any } = {
  home: HomePage,

  country_2: CountryPageAlternative,
  country: CountryPage,
  industry: IndustryPage,
  country_promotion: CountryPromotionPage,

  api: ApiPage,
  lead_generation: LeadGenerationPage,
  insights: InsightsPage,
  sales_force: SalesForcePage,
  fresh_investigation: FreshInvestigationPage,

  about: AboutUsPage,
  our_data: OurDataPage,
  careers: CareersPage,
  vacancy: VacancyPage,

  customers: CustomersPage,
  pricing: PricingPage,
  book_demo: BookDemoPage,
  contacts: ContactUsPage,
  thank_you: ThankYouPage,

  article: ArticlePage,
  blog: BlogPage,
  author: AuthorPage,

  policy: PolicyPage,

  // pricing module
  pricing_products: ProductPricingPage,
  pricing_trial: PricingTrial,

  // learning module
  academy: AcademyPage,
  course: ProspectingPlatform,

  // AI Agents module
  ai_agents: AgentsPage,

  // enrichment module
  enrichment: EnrichmentPage,

  // promotion module
  mexico: MexicoPage,
};
