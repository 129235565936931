/* eslint react-hooks/exhaustive-deps: "warn" */

import { useRef, useEffect, useState, useContext, useMemo, useCallback } from 'react';

import dynamic from 'next/dynamic';

import { useRouter } from 'libs/router/useRouter';
import { clearTimeoutIfExists } from 'libs/node';
import { capitalize } from 'libs/string';

import { ContentContext } from 'context/ContentContext';

import { useProducts } from 'hooks/useProducts';
import { useResizeListener } from 'hooks/useResizeListener';

import { Loader } from 'ui/atoms/Loader/Loader';
import { HeroText } from 'ui/atoms/HeroText/HeroText';
import { Faq } from 'ui/atoms/Faq/Faq';
import { PresentationBlockLoader } from 'ui/organisms/PresentationBlock/PresentationBlockLoader';
import { OurClients } from 'ui/molecules/OurClients/OurClients';
import { PlansTable } from 'ui/molecules/PlansTable/PlansTable';
import { DynamicImport } from 'ui/DynamicImport';
import { ButtonGroup } from 'ui/atoms/Button/ButtonGroup/ButtonGroup';
import { Button } from 'ui/atoms/Button/Button';

import { FrequencySwitch } from 'features/product-pricing/organisms/FrequencySwitch/FrequencySwitch';
import { PricingCards } from 'features/product-pricing/organisms/PricingCards/PricingCards';

import styles from './ProductPricingPage.module.scss';

const PADDING_BOTTOM_GAP = 150;

const frequencies = [
  { value: 'month', label: 'Bill monthly' },
  { value: 'year', label: 'Bill yearly', discount: 20 },
];

const baseClass = 'gdb-product-pricing';

const ProductPricingPage = () => {
  const debounceSend = useRef<NodeJS.Timeout>();
  const { query, push } = useRouter();
  const { config, secondaryNavigation, additionals, pageVars } = useContext(ContentContext);

  /** This state is considered only if hasFrequency is true */
  const [frequency, setFrequency] = useState(config.is_yearly_default_on_pricing ? 'year' : 'month');
  const [activeType, setActiveType] = useState<string>();

  const redirect = useCallback(() => {
    return push(secondaryNavigation.pricing.slug, { lang: `${query.lang}` });
  }, [push, secondaryNavigation.pricing.slug, query.lang]);
  const { data, loading, iframeData } = useProducts(redirect);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);
  const pricingPageVars = useMemo(() => pageVars.pricing_products || {}, [pageVars.pricing_products]);

  const pricingTypes = useMemo(() => {
    return Object.keys(
      data.reduce<Record<string, undefined>>((acc, current) => {
        if (current.type !== null) {
          acc[current.type] = undefined;
        }

        return acc;
      }, {}),
    ).sort();
  }, [data]);

  const sendDocumentHeight = useCallback(() => {
    if (loading) {
      return;
    }

    const height = document.getElementById('__next')?.offsetHeight || 0;

    if (!window.top) {
      return;
    }

    window.top.postMessage({ height: height + PADDING_BOTTOM_GAP }, '*');
  }, [loading]);

  useEffect(() => {
    const eventVisibilityChange = [
      'visibilitychange',
      () => {
        if (document.visibilityState === 'visible') {
          debounceSend.current = setTimeout(() => {
            sendDocumentHeight();
          }, 250);
        }
      },
    ] as const;

    document.addEventListener(...eventVisibilityChange);

    return () => {
      clearTimeoutIfExists(debounceSend.current);

      document.removeEventListener(...eventVisibilityChange);
    };
  }, [sendDocumentHeight]);

  useEffect(() => {
    if (pricingTypes.length > 0) {
      setActiveType(pricingTypes[0]);
    }
  }, [pricingTypes]);

  const debouncedSendDocumentHeight = useCallback(() => {
    clearTimeoutIfExists(debounceSend.current);

    debounceSend.current = setTimeout(() => {
      sendDocumentHeight();
    }, 250);
  }, [sendDocumentHeight]);

  useResizeListener(() => {
    sendDocumentHeight();
  }, [sendDocumentHeight]);

  const hasFrequency = useMemo(() => {
    /**
     * Year price is mandatory, it can be blank, but only together with month price
     * which basically means that it is Call to action offer (e.g. book a demo, call & etc).
     */

    return data.reduce((acc, current) => {
      if (acc) {
        acc = (Boolean(current.price_month) && Boolean(current.price_year)) === false || Boolean(current.price_month);
      }

      return acc;
    }, true);
  }, [data]);

  const renderedPresentationBlock = useMemo(
    () => (
      <DynamicImport fallback={() => <PresentationBlockLoader />}>
        {() => {
          const Component = dynamic(() => import('ui/organisms/PresentationBlock/PresentationBlock'), {
            ssr: false,
            loading: () => <PresentationBlockLoader />,
          });

          return <Component id={activeType || iframeData.presentationId || 'aggregator-white-bg'} className="mb-55" />;
        }}
      </DynamicImport>
    ),
    [activeType, iframeData.presentationId],
  );

  if (loading) {
    return <Loader loading height="calc(100vh - 140px)" />;
  }

  return (
    <>
      <HeroText className={styles[`${baseClass}-hero-1`]} size="lg">
        {iframeData.title || pricingPageVars.title}
      </HeroText>

      {pricingTypes.length > 0 && (
        <>
          <HeroText className={styles[`${baseClass}-hero-2`]} tagName="h2" size="sm" font={3}>
            Choose the subscription that suits your business.
          </HeroText>

          <HeroText className={styles[`${baseClass}-hero-3`]} tagName="p" size="sm" font={3} width={500}>
            Explore our packages and discover how to increase sales, reduce fraud, and minimize risk.
          </HeroText>

          <ButtonGroup className={styles[`${baseClass}-tabs`]}>
            {pricingTypes.map((type) => (
              <Button
                key={type}
                size={48}
                type={activeType !== type ? 'invert' : undefined}
                onClick={() => setActiveType(type)}
                loadingDelay={250}
              >
                {pricingPageVars[type] || capitalize(type.replace('_', ' '))}
              </Button>
            ))}
          </ButtonGroup>
        </>
      )}

      {hasFrequency && iframeData.hasFrequencySwitcher !== false && (
        <FrequencySwitch data={frequencies} value={frequency} onChange={setFrequency} />
      )}

      <PricingCards
        frequency={hasFrequency ? frequency : 'year'}
        callToActionLink={iframeData.ctaLink}
        callToActionText={iframeData.ctaText}
        callToActionType={iframeData.ctaType}
        data={iframeData.groupedByType ? data.filter((i) => i.type === activeType) : data}
        activeType={activeType}
      />

      <PlansTable data={data} footnote={true} />

      {renderedPresentationBlock}

      <OurClients />

      {additionals.faq && additionals.faq.data.length > 0 && (
        <Faq
          className="pb-55"
          title={commonPageVars.faq_2}
          data={additionals.faq.data}
          onClick={debouncedSendDocumentHeight}
        />
      )}
    </>
  );
};

/**
 * please, ignore export default error
 * Only pages require to have export default as they are imported dynamically
 */

// eslint-disable-next-line import/no-default-export
export default ProductPricingPage;
