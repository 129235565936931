/* eslint react-hooks/exhaustive-deps: "warn" */
import { RefObject, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';

import type { DataItem, HeadersItem, SetupData, UploadResponse, Config } from 'libs/http/api/dashboard/dashboard.types';
import type { $Object } from 'libs/object/object.types';
import { useRouter } from 'libs/router/useRouter';
import { dashboard } from 'libs/http/api/dashboard/dashboard';
import { setLocalStorage } from 'libs/localStorage';
import { invertObject } from 'libs/node';

import { ContentContext } from 'context/ContentContext';
import type { LeadData } from 'context/LeadContext';

import { useStateHandlers } from 'hooks/useStateHandlers';
import { useRequest } from 'hooks/useRequest';

import { ErrorMessage } from 'ui/atoms/ErrorMessage/ErrorMessage';
import { TooltipTrigger } from 'ui/molecules/TooltipTrigger/TooltipTrigger';
import { TagLabel } from 'ui/atoms/TagLabel/TagLabel';
import { Button } from 'ui/atoms/Button/Button';

import { ConfigureColumns } from 'features/enrichment/atoms/ConfigureColumns/ConfigureColumns';
import { UploadDropzone } from 'features/enrichment/organisms/UploadDropzone/UploadDropzone';
import { ModalFormBook } from 'features/articles/organisms/ModalFormBook';

import styles from './CompaniesUploadTemplate.module.scss';

export type UploadData = {
  file?: File;
  data?: DataItem[];
  headers?: HeadersItem[];
  session_key?: string;
};

interface Props {
  onChangeType?: (value: string, key: string) => void;
  modal?: boolean;
  modalRef?: RefObject<any> | undefined;
  uploadData?: UploadData;
}

export const CompaniesUploadTemplate = ({ onChangeType, modal = false, uploadData = {}, modalRef }: Props) => {
  const { query, push } = useRouter();
  const { secondaryNavigation } = useContext(ContentContext);

  const { request: requestConfig, data: dataConfig, loading: loadingConfig } = useRequest<Config>({ data: [] });
  const { request: requestUpload, loading: loadingUpload, errors: errorsUpload } = useRequest<UploadResponse>();
  const {
    request: requestSetup,
    loading: loadingSetup,
    errors: errorsSetup,
  } = useRequest<SetupData>({ data: {} as SetupData });

  const [showModal, setShowModal] = useState(false);
  const [showModalTrial, setShowModalTrial] = useState(false);

  const [state, setState] = useStateHandlers({
    file: {} as File,
    data: [] as DataItem[],
    headers: [] as HeadersItem[],
    config: {} as $Object,
    session_key: '',
    firstRowHeaders: false,

    full_name: '',
    phone: '',
    email: '',
  });

  const filterErrors = useMemo(() => {
    const emailErrors = (errorsSetup.email as string[]) || [];

    if (emailErrors.includes('Email is invalid or already taken')) {
      const { email, ...rest } = errorsSetup;
      return rest;
    }

    return errorsSetup;
  }, [errorsSetup]);

  useEffect(() => {
    requestConfig(dashboard.config({ lang: `${query.lang}` }));
  }, [query.lang, requestConfig]);

  useEffect(() => {
    const hasEmailError = errorsSetup?.email?.includes('Email is invalid or already taken');

    if (hasEmailError && Object.keys(filterErrors).length === 0) {
      onToggleModal();
      setShowModalTrial(hasEmailError);
    }
  }, [errorsSetup, filterErrors]);

  const isCombinationValid = useCallback(
    (userCombination: string[] = []): boolean =>
      dataConfig.reduce<boolean>((acc, serverCombination) => {
        if (acc === false && serverCombination.length === userCombination.length) {
          acc = serverCombination.reduce<boolean>((acc1, current1) => {
            if (acc1) {
              acc1 = userCombination.includes(current1);
            }

            return acc1;
          }, true);
        }

        return acc;
      }, false),
    [dataConfig],
  );

  const onSelectColl = useCallback(
    (target: number, newValue: string): void =>
      setState((prevState) => {
        const configClone: { [key: string]: any } = { ...prevState.config };
        const configNext: { [key: string]: any } = { ...configClone, [target]: newValue };

        /** clear selection */
        if (newValue === undefined) {
          delete configClone[target];

          return { config: configClone };
        }

        /** replace value's column */
        Object.keys(configClone).forEach((key) => {
          if (configClone[key] === newValue) {
            delete configClone[key];
          }
        });

        /** check if the next combination is correct */
        const configNextEntries: [string, any][] = Object.entries(configNext).map((entry) => entry);

        if (isCombinationValid(configNextEntries.map((entry) => entry[1])) === false) {
          const newCombination = dataConfig.reduce((acc, current) => {
            if (current.includes(newValue)) {
              acc = current;
            }

            return acc;
          }, []);

          configNextEntries.forEach((entry) => {
            const [key, value] = entry;
            if (!newCombination.includes(value)) {
              if (configClone[key] === value) {
                delete configClone[key];
              }
            }
          });
        }

        configClone[target] = newValue;

        return { config: configClone };
      }),
    [dataConfig, isCombinationValid, setState],
  );

  const onSubmit = useCallback(
    async (leadData: LeadData) => {
      const data = await requestSetup(
        dashboard.setup({
          name: state.file.name,
          config: invertObject(state.config),
          first_row_headers: state.firstRowHeaders,
          session_key: state.session_key,

          email: leadData.email,
          full_name: leadData.full_name,
          phone: leadData.phone,
        }),
      );

      if (typeof onChangeType === 'function') {
        onChangeType(data.status, state.session_key);
      }

      push(secondaryNavigation.enrichment?.slug, { lang: `${query.lang}` });
    },
    [
      onChangeType,
      push,
      query.lang,
      requestSetup,
      secondaryNavigation.enrichment?.slug,
      state.config,
      state.file.name,
      state.firstRowHeaders,
      state.session_key,
    ],
  );

  useEffect(() => {
    /** Hide Enrichment Modal if Form Modal is open */
    if (modalRef?.current !== undefined) {
      modalRef.current.style.display = (showModal || showModalTrial) && modal ? 'none' : 'block';
    }
  }, [showModal, showModalTrial, modal, modalRef]);

  useEffect(() => {
    if (Object.keys(uploadData).length > 0) {
      setLocalStorage('enrichment_session', uploadData.session_key);

      setState({
        file: uploadData.file,
        data: uploadData.data,
        headers: uploadData.headers,
        session_key: uploadData.session_key,
      });
    }
  }, [setState, uploadData]);

  const onUploadFile = async (files: File[] = []) => {
    if (files.length >= 1) {
      const formData = new FormData();
      formData.append('file', files[0] as File);

      const { data, headers, session_key } = await requestUpload(dashboard.upload(formData));

      setState({ file: files[0] || {}, data, headers, session_key });
    }
  };

  const onChangeFirstRowHeaders = (newChecked: boolean) => setState({ firstRowHeaders: newChecked });

  const onCancel = () => setState({ file: {} as File, config: {} });

  const onToggleModal = () => setShowModal((s) => !s);
  const onToggleModalTrial = () => setShowModalTrial((s) => !s);

  const type = useMemo(() => (state.file?.name === undefined ? 'add-file' : 'select-column'), [state.file]);

  const combinationValid = useMemo(
    () => isCombinationValid(Object.values(state.config)),
    [isCombinationValid, state.config],
  );

  return (
    <>
      {showModal && (
        <ModalFormBook
          maxWidth={630}
          title="Start matching"
          onSubmit={onSubmit}
          loading={loadingSetup}
          errors={filterErrors}
          onClose={onToggleModal}
        />
      )}

      {showModalTrial && (
        <ModalFormBook
          maxWidth={630}
          title="Request free trial"
          description="Oops! This email is already taken. You can request free trial demo and one of our managers will contact you as soon as possible."
          onClose={onToggleModalTrial}
        />
      )}

      <div className={styles['companies-upload']}>
        <div className={styles['companies-upload_stage']}>
          <div className={styles['companies-upload_title']}>
            <p>1. Upload file</p>

            <TooltipTrigger
              text="(?)"
              tooltipText={`
                Select the list of companies you would like Global Database to Match & Enrich and drop the file into the
                window below. The file must at least contain a list of Business Names and in which
                Countries they are located.
              `}
            />
          </div>

          {type === 'add-file' && (
            <div className={styles['companies-upload_dropzone']}>
              <UploadDropzone onDrop={onUploadFile} loading={loadingUpload} />

              {errorsUpload.detail && <ErrorMessage error={errorsUpload.detail} position="right" />}
            </div>
          )}

          {type === 'select-column' && state.file.name.length > 0 && (
            <TagLabel text={state.file.name} status="active" onClose={onCancel} />
          )}
        </div>

        <div className={cn(styles['companies-upload_stage'], type === 'add-file' && styles['inactive'])}>
          <div className={styles['companies-upload_title']}>
            <p>2. Configure columns</p>

            <TooltipTrigger
              text="(?)"
              tooltipText={`
                Choose which columns Global Database should use to match companies and executives from your list with our
                own datasets. Country is a required field to match by.
              `}
            />
          </div>

          {type === 'select-column' && (
            <ConfigureColumns
              data={state.data}
              config={state.config}
              headers={state.headers}
              loading={loadingConfig}
              onSelectColl={onSelectColl}
              onChangeFirstRowHeaders={onChangeFirstRowHeaders}
              firstRowHeaders={state.firstRowHeaders}
              modalRef={modalRef}
            />
          )}
        </div>

        <div className={cn(styles['companies-upload_stage'], type === 'add-file' && styles['inactive'])}>
          <div className={styles['companies-upload_title']}>
            <p>3. Choose what to save and download</p>

            <TooltipTrigger
              text="(?)"
              tooltipText={`
                Select companies and executives you would like to save or download from a selection of Matched & Enriched
                profiles.
              `}
            />
          </div>

          {type === 'select-column' && <p className={styles['companies-upload-desc']}>We will match rows based on:</p>}

          {state.headers.length > 0 && (
            <div className={styles['companies-upload_tags']}>
              {state.headers.map((tag) => {
                const active =
                  Object.keys(state.config).filter((index) => state.config[index] === tag.codename).length > 0;

                return (
                  <TagLabel
                    key={tag.codename}
                    text={tag.name}
                    status={active ? 'active' : ''}
                    prefix={active ? 'check-circle' : 'cross-circle'}
                  />
                );
              })}
            </div>
          )}
        </div>

        <Button size={48} onClick={onToggleModal} disabled={combinationValid === false}>
          Start matching
        </Button>
      </div>
    </>
  );
};
