import { useMemo } from 'react';

import { Product } from 'libs/http/api/content/content.types';

import { Icon } from 'ui/atoms/Icon/Icon';

import styles from './PricingCardDescription.module.scss';

interface Props {
  currency?: string;
  frequency?: string;
  standalone?: boolean;
  data: Product['pricing_feature_groups'];
  features: string;
  choices: string;
}

export const PricingCardDescription = ({ currency, frequency, standalone, data, features, choices }: Props) => {
  const priceTag = useMemo(() => `price_${frequency || 'month'}` as 'price_month' | 'price_year', [frequency]);

  return (
    <div className={styles['description-wrapper']}>
      {features && (
        <div className={styles['card-accounts']}>
          {features?.split('\n').map((feature) => (
            <div key={feature} className={styles['card-service']}>
              <span>{feature}</span>

              <Icon type="check-circle" />
            </div>
          ))}
        </div>
      )}

      {choices && (
        <div className={styles['card-accounts']}>
          <p>Choose from</p>
          {choices?.split('\n').map((choice) => (
            <div key={choice} className={styles['card-service']}>
              <span>{choice}</span>

              <Icon type="check-circle" />
            </div>
          ))}
        </div>
      )}

      {data.filter((i) => i).length > 0 && (
        <div className={styles['card-tools']}>
          {standalone !== true && data.length > 1 && <p>Included tools and it’s value</p>}

          <div className={styles['card-block']}>
            {data
              .filter((item) => item.description)
              .map((item, key) => (
                <div key={key} className={styles['card-tool']}>
                  <Icon type={item.icon_type} />

                  <div
                    className={
                      Boolean(item.name) === false ? styles['card-standalone-deals'] : styles['card-cost-wrapper']
                    }
                  >
                    <div className={styles['card-cost']}>
                      <p>{item.name}</p>

                      {Boolean(item[priceTag]) ? (
                        <span className={styles['card-cost-price']}>
                          {currency}
                          {item[priceTag]}
                        </span>
                      ) : (
                        item.has_no_checkmark !== true && <Icon type="check-circle" />
                      )}
                    </div>

                    {item?.description?.split('\n').map((desc) => (
                      <span className={styles['card-tool-desc']} key={desc}>
                        {desc}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
