/* eslint react-hooks/exhaustive-deps: "warn" */

import { useEffect, useState, useCallback } from 'react';

import cn from 'classnames';
import Head from 'next/head';

import { Faq as FaqType } from 'libs/http/api/content/content.types';

import { Icon } from 'ui/atoms/Icon/Icon';

interface Props {
  className?: string;
  title?: string;
  data: FaqType[];
  onClick?: () => void;
}

export const Faq = ({ className = '', title, data, onClick }: Props) => {
  const [active, setActive] = useState<string[]>([]);

  useEffect(() => {
    setActive(
      data.reduce<typeof active>((acc, current) => {
        if (current.default_opened) {
          acc.push(current.id);
        }

        return acc;
      }, []),
    );
  }, [data]);

  const onToggle = useCallback(
    (id: (typeof active)[number]) => () => {
      if (typeof onClick === 'function') {
        onClick();
      }

      setActive((s) => (s.includes(id) ? s.filter((i) => i !== id) : [...s, id]));
    },
    [onClick],
  );

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: data
                .filter((item) => item.disabled !== true)
                .map((item) => ({
                  '@type': 'Question',
                  name: item.question,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: item.answer,
                  },
                })),
            }),
          }}
        />
      </Head>

      <div className={cn('asked-questions', className)}>
        {title && <h2>{title}</h2>}

        <div className="asked-questions__wrapper">
          <div className="asked-questions__blocks">
            {data.map((item) => {
              const isActive = active.includes(item.id);
              const isDisabled = item.disabled === true;

              return (
                <div key={item.id} className={isDisabled ? 'question-disabled' : ''}>
                  <h2 onClick={isDisabled === false ? onToggle(item.id) : undefined}>{item.question}</h2>

                  <Icon type="rotate" className={isActive ? 'active' : 'unactive'} />

                  {item.answer && (
                    <div className={isActive ? 'question-block-item' : 'question-block-item-hidden'}>
                      <div dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
