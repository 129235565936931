import React, { useContext, useMemo } from 'react';

import { ContentContext } from 'context/ContentContext';
import type { LeadData } from 'context/LeadContext';

import { Modal } from 'ui/organisms/Modal/Modal';

import { FormBook } from 'features/customers/organisms/FormBook/FormBook';

type Props = {
  onClose: () => void;
  onSubmit?: (leadData: LeadData) => void;
  title?: string;
  description?: string;
  loading?: boolean;
  maxWidth?: number;
  errors?: {
    [key: string]: string | string[];
  };
};

export const ModalFormBook = ({ onClose, title, description, maxWidth, ...props }: Props) => {
  const { pageVars } = useContext(ContentContext);

  const commonPageVars = useMemo(() => pageVars.common || {}, [pageVars.common]);

  return (
    <Modal
      onClose={onClose}
      maxWidth={maxWidth}
      title={title || commonPageVars.request_sample_button}
      description={description}
    >
      <FormBook type="book" {...props} />
    </Modal>
  );
};
