import { type ReactNode, useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

import cn from 'classnames';

import { cabinClassNames } from 'font';

import { clearTimeoutIfExists } from 'libs/node';

import { useEventListener } from 'hooks/useEventListener';

import styles from './Tooltip.module.scss';

interface Props {
  delay?: number;
  label: string | number | null | ReactNode;
  children: ReactNode;
  tooltipClassName?: string;
}

export const Tooltip = ({ delay, label, children, tooltipClassName }: Props) => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const [csr, setCSR] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);

  useEffect(() => {
    setCSR(true);
  }, []);

  useEventListener('scroll', () => {
    setIsVisible(false);
  });

  useEffect(() => {
    const cb = () => {
      if (isVisible && targetRef.current && tooltipRef.current) {
        const targetRect = targetRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();

        const centerX = targetRect.left + targetRect.width / 2;

        const left = centerX - tooltipRect.width / 2;
        const top = targetRect.top - tooltipRect.height - 10;

        setPosition({
          top: top < 0 ? 0 : top,
          left: left < 0 ? 0 : left,
        });
      }
    };

    if (delay && delay > 0) {
      const timer = setTimeout(cb, delay);

      return () => {
        clearTimeoutIfExists(timer);
      };
    }

    cb();
  }, [delay, isVisible]);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  return (
    <div className={styles['tooltip-wrapper']}>
      <div ref={targetRef} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {children}
      </div>

      {csr && isVisible && label !== null
        ? createPortal(
            <div
              ref={tooltipRef}
              className={cn(styles['tooltip'], tooltipClassName, cabinClassNames)}
              style={{ top: `${position?.top}px`, left: `${position?.left}px` }}
            >
              {label}
              <div className={styles['tooltip-arrow']} />
            </div>,
            document.body,
          )
        : null}
    </div>
  );
};
