import styles from './Price.module.scss';

interface PriceProps {
  currency: string;
  frequency?: string;
  size?: 'lg' | 'sm';
  value: number;
}

export const Price = (props: PriceProps) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={`${styles[`price-${props.size}`]}`}>
        {props.currency}
        {props.value}
      </h3>

      {props.frequency ? <>/{props.frequency}</> : null}
    </div>
  );
};
